.screenContainer{
  padding-left: 32px;
}
.backBtn {
  display: flex;
  align-items: center;
  background-color: transparent;
  gap: 6px;
  margin-bottom: 32px;
  span {
    color: var(--green-600);
  }
}
.container {
  border: 1px solid var(--grey-200);
  border-radius: 6px;
  margin-bottom: 40px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid var(--grey-200);
}

.personalDetails {
  display: flex;
  align-items: center;
  gap: 14px;

  div:first-of-type {
    background-color: var(--grey-50);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 1px solid var(--grey-200);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: var(--grey-500);
    }
  }

  div:last-of-type {
    h5 {
      color: var(--grey-700);
    }
    h6 {
      color: var(--grey-700);
    }
  }
}

.copyLinkBtn {
  border: 1px solid var(--grey-200);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: transparent;
  padding: 10px 16px;
  span {
    color: var(--grey-700);
  }
}

.tabsContainer {
  display: flex;
}

.tabSection1 {
  flex-basis: 33.33%;
  padding: 0 32px;
  border-right: 1px solid var(--grey-200);
  padding-bottom: 100px;
}
.tabSection2 {
  flex-basis: 66.66%;
  padding: 0 32px;
  padding-bottom: 100px;
}
.recording {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 32px;
  border-bottom: 1px solid var(--grey-200);
  overflow-x: hidden;
}

.recording .settingsIconContainer {
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  border-radius: 4px;
}

.recording .settingsIconContainer:hover {
  background: #f7faf8 !important;
}
.recording-details label:not(:last-child) {
  margin-bottom: 24px;
}

.recording-details input[type="text"]:disabled {
  background: #f5f7f6;
}
.pausePlayBtn {
  border: 1.6px solid var(--green-500);
  width: 48px;
  height: 48px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.waveContainer {
  flex: 1;
  overflow-x: scroll;
}
.waveContainer::-webkit-scrollbar {
  background: #2a2a2a95;
  width: 4px;
  opacity: 0;
  height: 0;
}
.waveContainer wave {
  overflow: hidden !important;
}