.account-container {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  overflow-x: hidden;
}

.account-container .content {
  width: 60%;
  margin-bottom: 32px;
}

.account-container .content > label > input {
  margin-top: 10px;
}

.account-container .content > label {
  display: flex;
  flex-direction: column;
}

.account-container .section > .title {
  margin-bottom: 24px;
  font-family: "Inter", sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #464d48 !important;
}

.account-container .section > button {
  background-color: #34cb65;
  padding: 4px 30px 4px 30px;
  border-radius: 4px;
  font-weight: 600;
}

.account-container .section > button:hover {
  background-color: #34cb65;
}

.virtual-numbers > .content {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* t6 regular-font nc-gray-900 */

.virtual-numbers > .content > .number {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  border: 1px solid #dedede;
  color: #464d48;
  padding: 5px 10px 5px 10px;
}

.virtual-numbers > .content > .number > img {
  margin-right: 8px;
}

.virtual-numbers > .content > p {
  font-size: 1em;
  margin: 0px 16px 0px 16px;
  color: #464d48;
}

/* +91 after conttected to */
.virtual-numbers > .content > div > div {
  padding-left: 22px;
  padding-right: 22px;
  color: #464d48;
}

/* input field to change virtual number */
.virtual-numbers > .content > div > input {
  color: #464d48;
  font-size: 16px;
  padding: 9px 12px;
}

.account-container .country-code {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  text-align: center;
  background-color: #f7f7f7;
  border: 1px solid #dedede;
}

.profile > .content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 18px;
  grid-column-gap: 20px;
}

.password > .content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 18px;
  grid-column-gap: 20px;
}

.new-password {
  grid-column: 1;
}

.account-container input {
  width: 275px;
  height: 36px;
  border: 1px solid #dedede;
  padding-left: 10px;
}

.account-container input:focus {
  outline: none;
}

.settings-container .account-container .settings-sep {
  margin-bottom: 40px;
  margin-top: 40px;
}

.business-hours > .content {
  width: 20%;
  display: grid;
  grid-row-gap: 24px;
}

.time-slot-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.business-hours > .content p {
  margin: 0 1em;
}

.business-hours > .content label {
  /* margin-bottom: 20px; */
}

.authenticator-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.authenticator-screen p {
  font-family: "Inter";
  font-size: 14px;
  line-height: 20px;
  color: #3f3f46;
  margin: 28px 0 20px 0;
}
.email-screen p {
  font-family: "Inter";
  font-size: 14px;
  line-height: 20px;
  color: #3f3f46;
  margin: 0px 0 16px 0;
}
.email-screen span {
  font-weight: 500;
}
.code-container {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.code-container input {
  width: 56.67px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e4e4e7;
  border-radius: 6px;
  text-align: center;
}
