.container {
  padding: 32px;
}
.renewalInfo {
  border: 1px solid #e1e5e2;
  padding: 16px;
  border-radius: 6px;
  width: 748px;
}
.container section {
  margin: 40px auto;
}
.container section:first-child {
  margin-top: 0;
}
.table {
  border: 1px solid #e1e5e2;
  border-radius: 6px;
  margin-top: 32px;
  width: 748px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: #f5f7f6;
}
.header > div {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.row {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row > span:nth-child(2) {
  color: #2eb258;
  text-decoration: underline;
  cursor: pointer;
}
.renewDate {
  font-weight: 500;
}
.accountUpgradedBox {
  border-radius: 6px;
  border: 1px solid #34cb65;
  background: #fff;
  padding: 24px;
  width: 748px;
}
.accountUpgradedBox > div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.accountUpgradedBox > div:first-child > span {
  cursor: pointer;
}
.accountUpgradedBox ul {
  padding-left: 24px;
  line-height: 160%;
  margin-bottom: 0;
  margin-top: 8px;
}
.accountUpgradedBox button {
  color: #2eb258;
  text-decoration: underline;
  cursor: pointer;
  background-color: #fff;
}
