.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    position: static;
    height: calc(100vh - 4em)
}

.infoContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 1.25px solid #e1e5e2;
    border-radius: 10px;
    padding: 40px 32px;
    width: 461px;
    text-align: center
}

.info {
   line-height:160%;
   margin-top:0;
}